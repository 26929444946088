import axios from '../../axios'
import qs from 'qs';

const api_ref_produk = "/DataReferensi"
// const headerKey = "910LKNmmai671YujanXnmXJkaiqYRrbcvf"

const state = {
  dataItemPrioritas: "",
}

const mutations = {
  setDataItemPrioritas(state, dataItemPrioritas) {
    state.dataItemPrioritas = dataItemPrioritas;
  },
}

const actions = {
  getDataItemPrioritas({ commit }, id) {
    return new Promise(function (resolve, reject) {
      axios.get(api_ref_produk + '/Info/Produk/' + id, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setDataItemPrioritas', res.data)
          } else {
            commit('setDataItemPrioritas', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addDataItem({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_ref_produk + '/Produk', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editDataItem({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_ref_produk + '/Produk',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteDataItem({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_ref_produk + '/Produk',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addItemLink({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_ref_produk + '/Produk/URL', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editItemLink({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_ref_produk + '/Produk/URL',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteItemLink({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_ref_produk + '/Produk/URL',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addItemImage({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_ref_produk + '/Produk/Gambar', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editItemImage({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_ref_produk + '/Produk/Gambar',
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteItemImage({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_ref_produk + '/Produk/Gambar',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
}

const getters = {
  dataItemPrioritas(state) {
    return state.dataItemPrioritas;
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}


