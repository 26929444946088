import axios from 'axios'

const instance = axios.create({
  // development
  baseURL: "https://aperdu-dev.adeecan.com/backend/"

  // production
  // baseURL: "https://aperdu.adeecan.com/backend/"
})

instance.defaults.timeout = 10000;  // 10 sec

export default instance