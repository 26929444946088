const state = {
  isLoading: false,
  status: null
}

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setStatus(state) {
    state.status = 'success'
  }
}

const actions = {
  showLoading({ commit }, isLoading) {
    commit('setLoading', isLoading)
  }
}

const getters = {
  isLoading(state) {
    return state.isLoading
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}