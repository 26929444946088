import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import interceptor from './interceptor'
import store from './store/store'

Vue.config.productionTip = false

// control enable / disable console.log
// var _log = console.log;
console.log = function(){
  // Do something with the log message
  // _log.apply(console,arguments);
};

Vue.filter('replaceString', function (value) {
  if (value) {
    return value.toString()
      .replace("GET", "Lihat")
      .replace("POST", "Input")
      .replace("PUT", "Edit")
      .replace("DELETE", "Hapus")
      .replaceAll(",", ", ")
  }
});

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

new Vue({
  vuetify,
  router,
  interceptor,
  store,
  render: h => h(App)
}).$mount('#app')
