import axios from '../../axios'
import qs from 'qs';

const api_usergroup = "/DataUserGroup"
const headerKey = "17jammai671YujanbcvfsRtqyi8I81717YgfarEwqJmkl"

const state = {
  listUserGroup: [],
  detailUserGroup: "",
  listModules: [],
}

const mutations = {
  setListUserGroup(state, listUserGroup) {
    state.listUserGroup = listUserGroup
  },

  setDetailUserGroup(state, detailUserGroup) {
    state.detailUserGroup = detailUserGroup
  },

  setListModules(state, listModules) {
    state.listModules = listModules
  },
}

const actions = {
  getListUserGroup({ commit }, params) {
    return new Promise(function (resolve, reject) {
      axios.get(api_usergroup + '/Daftar/Group', {
        headers: {
          "Key": headerKey,
          "Authorization": atob(localStorage.getItem('token'))
        },
        params: {
          group: params.group,
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListUserGroup', res.data.data)
          } else {
            commit('setListUserGroup', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getDetailUserGroup({ commit }, id) {
    return new Promise(function (resolve, reject) {
      axios.get(api_usergroup + '/Detail/Group/' + id, {
        headers: {
          "Key": headerKey,
          "Authorization": atob(localStorage.getItem('token'))
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setDetailUserGroup', res.data.data)
          } else {
            commit('setDetailUserGroup', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getListModules({ commit }) {
    return new Promise(function (resolve, reject) {
      axios.get(api_usergroup + '/Daftar/Modul', {
        headers: {
          "Key": headerKey,
          "Authorization": atob(localStorage.getItem('token'))
        },
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListModules', res.data.data)
          } else {
            commit('setListModules', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  setAccess({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_usergroup + '/Akses',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteAccess({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_usergroup + '/Akses',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
}

const getters = {
  listUserGroup(state) {
    return state.listUserGroup
  },

  detailUserGroup(state) {
    return state.detailUserGroup
  },

  listModules(state) {
    return state.listModules
  },
}

export default {
  state,
  mutations,
  actions,
  getters
}