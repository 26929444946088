import axios from "./axios"
import store from "./store/store"

const interReq = axios.interceptors.request.use(
  config => {
    store.dispatch('showLoading', true)
    // console.log('config: ', config)
    return config;
  }
)

const interRes = axios.interceptors.response.use(
  res => {
    store.dispatch('showLoading', false)
    // console.log('res: ', res)
    return res
  },
  error => {
    store.dispatch('showLoading', false)
    // console.log('error', error)
    alert(error);
    return error
  }
)

export default {
  interReq,
  interRes
}