import axios from '../../axios'
import qs from 'qs';

const api_useradmin = "/UserAdminPusat"
// const headerKey = "mmUiImNbVFr156UxUI190mNAioiai671Yujanbc"

const state = {
  listUserAdmin: "",
  detailUserAdmin: "",

  // dataref
  listDataWilayah: [],
  listDataSatker: [],
  listDataGroup: [],
}

const mutations = {
  setListUserAdmin(state, listUserAdmin) {
    state.listUserAdmin = listUserAdmin
  },

  setDetailUserAdmin(state, detailUserAdmin) {
    state.detailUserAdmin = detailUserAdmin
  },

  // dataref
  setListDataWilayah(state, listDataWilayah) {
    state.listDataWilayah = listDataWilayah
  },
  setListDataSatker(state, listDataSatker) {
    state.listDataSatker = listDataSatker
  },
  setListDataGroup(state, listDataGroup) {
    state.listDataGroup = listDataGroup
  },
}

const actions = {
  getListUserAdmin({ commit }, params) {
    return new Promise(function (resolve, reject) {
      axios.get(api_useradmin + '/Daftar/User/' + params.page + '/' + params.limit, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
        params: params,
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListUserAdmin', res.data)
          } else {
            commit('setListUserAdmin', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getDetailUserAdmin({ commit }, id) {
    return new Promise(function (resolve, reject) {
      axios.get(api_useradmin + '/Detail/User/' + id, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setDetailUserAdmin', res.data.data)
          } else {
            commit('setDetailUserAdmin', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addUserAdmin({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_useradmin + '/User', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editUserAdmin({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_useradmin + '/user',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteUserAdmin({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_useradmin + '/User',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  resetUserAdmin({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_useradmin + '/user/Password',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  setGroupUserAdmin({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_useradmin + '/user/Group',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  removeGroupUserAdmin({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_useradmin + '/User/Group',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  // dataref
  getListDataWilayah({ commit }) {
    return new Promise(function (resolve, reject) {
      axios.get(api_useradmin + '/Daftar/Kelompok', {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListDataWilayah', res.data.data)
          } else {
            commit('setListDataWilayah', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getListDataSatker({ commit }, params) {
    return new Promise(function (resolve, reject) {
      axios.get(api_useradmin + '/Daftar/Satker', {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
        params: params,
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListDataSatker', res.data.data)
          } else {
            commit('setListDataSatker', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getListDataGroup({ commit }) {
    return new Promise(function (resolve, reject) {
      axios.get(api_useradmin + '/Daftar/UserGroup', {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListDataGroup', res.data.data)
          } else {
            commit('setListDataGroup', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
}

const getters = {
  listUserAdmin(state) {
    return state.listUserAdmin;
  },

  detailUserAdmin(state) {
    return state.detailUserAdmin;
  },

  // dataref
  listDataWilayah(state) {
    return state.listDataWilayah;
  },

  listDataSatker(state) {
    return state.listDataSatker;
  },

  listDataGroup(state) {
    return state.listDataGroup;
  },
}

export default {
  state,
  mutations,
  actions,
  getters
}