import axios from '../../axios'
import router from '../../router'
import qs from 'qs';

const api_auth = "/UserAuth"
const headerKey = "781961YujaXnjhaYtrqIonbcvfsRtqyi891Uiaop718"

const state = {
  userData: "",
  infoCaptcha: "",
}

const mutations = {
  setUserData(state, userData) {
    state.userData = userData
  },
  clearAuthData(state) {
    state.userData = ""
  },
  setInfoCaptcha(state, infoCaptcha) {
    state.infoCaptcha = infoCaptcha
  }
}

const actions = {
  login({ commit }, formData) {
    return new Promise(function (resolve, reject) {
      axios.post(api_auth + '/Login', formData, {
        headers: {
          "Key": headerKey
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == "success") {
            const token = res.data.token
            // encoded base64
            const encodedAuth = btoa(token)
            localStorage.setItem('token', encodedAuth)
            commit('setUserData', res.data)
            router.replace({ name: 'welcome' })
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  logout({ commit }) {
    return new Promise(function (resolve, reject) {
      axios.post(api_auth + '/Logout', "", {
        headers: {
          "Key": headerKey,
          "Authorization": atob(localStorage.getItem('token'))
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('clearAuthData');
            localStorage.removeItem("token");
            router.replace({ name: 'home' })
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getInfoUser({ commit }) {
    return new Promise(function (resolve, reject) {
      axios.get(api_auth + '/Info/Token', {
        headers: {
          "Key": headerKey,
          "Authorization": atob(localStorage.getItem('token'))
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setUserData', res.data.data)
          } else {
            commit('clearAuthData')
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getInfoCaptcha({ commit }) {
    return new Promise(function (resolve, reject) {
      axios.get(api_auth + '/Captcha', {
        headers: {
          "Key": headerKey,
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setInfoCaptcha', res.data.data)
          } else {
            commit('setInfoCaptcha', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  changeName({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_auth + '/Update/Nama',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Key": headerKey,
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  changePassword({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_auth + '/Update/Password',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Key": headerKey,
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
}

const getters = {
  userData(state) {
    return state.userData
  },
  infoCaptcha(state) {
    return state.infoCaptcha
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
