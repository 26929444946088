import axios from '../../axios'
import qs from 'qs';

const api_rkakl = "/DataRKAKL"
// const headerKey = "178910LKxYU189iSJMXONmmai671YujanXnmXJkai"

const state = {
  listURLRKAKL: [],
}

const mutations = {
  setListURLRKAKL(state, listURLRKAKL) {
    state.listURLRKAKL = listURLRKAKL;
  },
}

const actions = {
  getListURLRKAKL({ commit }, id) {
    return new Promise(function (resolve, reject) {
      axios.get(api_rkakl + '/Info/RKAKL/' + id, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListURLRKAKL', res.data.data)
          } else {
            commit('setListURLRKAKL', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addRKAKL({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_rkakl + '/RKAKL', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editRKAKL({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_rkakl + '/RKAKL',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteRKAKL({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_rkakl + '/RKAKL',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
}

const getters = {
  listURLRKAKL(state) {
    return state.listURLRKAKL;
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
}