import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: () => import('./layouts/Landing.vue'),
    children: [
      {
        path: '',
        name: 'home',
        meta: { requiresAuth: true },
        component: () => import('./components/Home.vue'),
      },
    ],
  },
  {
    path: '/welcome',
    component: () => import('./layouts/Default.vue'),
    children: [
      {
        path: '',
        name: 'welcome',
        component: () => import('./components/Welcome.vue'),
      },
    ],
  },
  {
    path: '/profil',
    component: () => import('./layouts/Default.vue'),
    children: [
      {
        path: '',
        name: 'profil',
        component: () => import('./components/Profile.vue'),
      },
    ],
  },
  {
    path: '/user-group',
    component: () => import('./layouts/Default.vue'),
    children: [
      {
        path: '',
        name: 'l_usergroup',
        component: () => import('./components/user-group/UserGroup.vue'),
      },
      {
        path: 'detail/:id',
        name: 'd_usergroup',
        component: () => import('./components/user-group/UserGroupDetail.vue'),
      },
    ],
  },
  {
    path: '/satker',
    component: () => import('./layouts/Default.vue'),
    children: [
      {
        path: '',
        name: 'l_kanwil',
        component: () => import('./components/satker/Kanwil.vue'),
      },
      {
        path: ':satker/:id',
        name: 'l_satker',
        component: () => import('./components/satker/Satker.vue'),
      },
    ],
  },
  {
    path: '/user-admin',
    component: () => import('./layouts/Default.vue'),
    children: [
      {
        path: '',
        name: 'l_useradmin',
        component: () => import('./components/user-admin/UserAdmin.vue'),
      },
      {
        path: 'detail/:id',
        name: 'd_useradmin',
        component: () => import('./components/user-admin/UserAdminDetail.vue'),
      },
    ],
  },
  {
    path: '/rkbmn',
    component: () => import('./layouts/Default.vue'),
    children: [
      {
        path: '',
        name: 'l_rkbmn',
        component: () => import('./components/rkbmn/rkbmn.vue'),
      },
      {
        path: 'detail/:id',
        name: 'd_rkbmn',
        component: () => import('./components/rkbmn/rkbmnDetail.vue'),
      },
    ],
  },
  {
    path: '/prioritas',
    component: () => import('./layouts/Default.vue'),
    children: [
      {
        path: '',
        name: 'l_prioritas',
        component: () => import('./components/prioritas/Prioritas.vue'),
      },
      {
        path: 'detail/:tahun/:id',
        name: 'd_prioritas',
        component: () => import('./components/prioritas/PrioritasDetail.vue'),
      },
      {
        path: 'detail/:tahun/:id/:produk/:idProduk',
        name: 'd_item',
        component: () => import('./components/ref_produk/RefProduk.vue'),
      },
    ],
  },
  {
    path: '/rkakl',
    component: () => import('./layouts/Default.vue'),
    children: [
      {
        path: '',
        name: 'l_rkakl',
        component: () => import('./components/rkakl/rkakl.vue'),
      },
      {
        path: 'detail/:tahun/:id',
        name: 'd_rkakl',
        component: () => import('./components/rkakl/rkaklDetail.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new Router({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (!token) {
    if (!to.meta.requiresAuth) {
      next({ name: "home" })
    } else {
      next()
    }
  } else {
    store.dispatch('getInfoUser').then((res) => {
      if (res.hasil == 'success') {
        if (to.meta.requiresAuth) {
          next({ name: "welcome" })
        } else {
          next()
        }
      } else {
        localStorage.removeItem("token")
        next({ name: "home" })
      }
    })
  }
})

export default router