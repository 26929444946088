import axios from '../../axios'
import qs from 'qs';

const api_prioritas = "/DataPrioritas"
// const headerKey = "0LKNXhuYiqommai671YujanXnmXJkaiqYRrbcvfs"

const state = {
  listPrioritas: "",
  detailPrioritas: "",
}

const mutations = {
  setListPrioritas(state, listPrioritas) {
    state.listPrioritas = listPrioritas;
  },
  setDetailPrioritas(state, detailPrioritas) {
    state.detailPrioritas = detailPrioritas;
  },
}

const actions = {
  getListPrioritas({ commit }, params) {
    return new Promise(function (resolve, reject) {
      axios.get(api_prioritas + '/Info/Daftar/' + params.page + '/' + params.limit, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
        params: params,
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListPrioritas', res.data)
          } else {
            commit('setListPrioritas', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getDetailPrioritas({ commit }, id) {
    return new Promise(function (resolve, reject) {
      axios.get(api_prioritas + '/Info/' + id, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setDetailPrioritas', res.data.data)
          } else {
            commit('setDetailPrioritas', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getLinkDocumentPrioritas({ commit }, filename) {
    return new Promise(function (resolve, reject) {
      axios.get(api_prioritas + '/Info/Unduhan/' + filename, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addPrioritas({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_prioritas + '/Prioritas', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editPrioritas({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_prioritas + '/Prioritas',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deletePrioritas({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_prioritas + '/Prioritas',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addLampiranPrioritas({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_prioritas + '/Prioritas/Lampiran', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editLampiranPrioritas({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_prioritas + '/Prioritas/Lampiran',
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteLampiranPrioritas({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_prioritas + '/Prioritas/Lampiran',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addItemBelanja({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_prioritas + '/Prioritas/Items', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editItemBelanja({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_prioritas + '/Prioritas/items',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteItemBelanja({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_prioritas + '/Prioritas/Items',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
}

const getters = {
  listPrioritas(state) {
    return state.listPrioritas;
  },
  detailPrioritas(state) {
    return state.detailPrioritas;
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
}