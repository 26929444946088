import axios from '../../axios'
import qs from 'qs';

const api_satker = "/DataSatker"
// const headerKey = "jam617YUuamI1710mUiqy71ImNbVFr156Uioiai671Yujanbcv"

const state = {
  listKanwil: [],
  detailKanwil: "",
  listSatker: [],
  infoDetailSatker: "",
}

const mutations = {
  setListKanwil(state, listKanwil) {
    state.listKanwil = listKanwil;
  },
  setDetailKanwil(state, detailKanwil) {
    state.detailKanwil = detailKanwil;
  },
  setListSatker(state, listSatker) {
    state.listSatker = listSatker;
  },
  setInfoDetailSatker(state, infoDetailSatker) {
    state.infoDetailSatker = infoDetailSatker;
  },
}

const actions = {
  getListKanwil({ commit }) {
    return new Promise(function (resolve, reject) {
      axios.get(api_satker + '/Daftar/Kelompok', {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListKanwil', res.data.data)
          } else {
            commit('setListKanwil', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getDetailKanwil({ commit }, id) {
    return new Promise(function (resolve, reject) {
      axios.get(api_satker + '/Detail/Kelompok/' + id, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setDetailKanwil', res.data.data)
          } else {
            commit('setDetailKanwil', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addKanwil({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_satker + '/Kelompok', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editKanwil({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_satker + '/Kelompok',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteKanwil({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_satker + '/Kelompok',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getListSatker({ commit }, params) {
    return new Promise(function (resolve, reject) {
      axios.get(api_satker + '/Daftar/Satker', {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
        params: params,
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListSatker', res.data)
          } else {
            commit('setListSatker', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getInfoDetailSatker({ commit }, id) {
    return new Promise(function (resolve, reject) {
      axios.get(api_satker + '/Info/Detail/Satker/' + id, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setInfoDetailSatker', res.data.data)
          } else {
            commit('setInfoDetailSatker', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addSatker({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_satker + '/Satker', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editSatker({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_satker + '/Satker',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteSatker({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_satker + '/Satker',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
}

const getters = {
  listKanwil(state) {
    return state.listKanwil;
  },
  detailKanwil(state) {
    return state.detailKanwil;
  },
  listSatker(state) {
    return state.listSatker;
  },
  infoDetailSatker(state) {
    return state.infoDetailSatker;
  },
}

export default {
  state,
  mutations,
  actions,
  getters
}