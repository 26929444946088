import Vue from 'vue'
import Vuex from 'vuex'
import general from './modules/general'
import auth from './modules/auth'
import usergroup from './modules/user-group'
import satker from './modules/satker'
import useradmin from './modules/user-admin'
import rkbmn from './modules/rkbmn'
import prioritas from './modules/prioritas'
import ref_produk from './modules/ref_produk'
import rkakl from './modules/rkakl'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    general,
    auth,
    usergroup,
    satker,
    useradmin,
    rkbmn,
    prioritas,
    ref_produk,
    rkakl,
  }
})