import axios from '../../axios'
import qs from 'qs';

const api_rkbmn = "/DataRKBMN"
// const headerKey = "178910LKNmmai671YujanXnmXJkaiqYRrbcvfsRt"

const state = {
  listRKBMN: "",
  detailRKBMN: "",
  infoListRKBMN: "",
}

const mutations = {
  setListRKBMN(state, listRKBMN) {
    state.listRKBMN = listRKBMN
  },
  setDetailRKBMN(state, detailRKBMN) {
    state.detailRKBMN = detailRKBMN
  },
  setInfoListRKBMN(state, infoListRKBMN) {
    state.infoListRKBMN = infoListRKBMN
  },
}

const actions = {
  getListRKBMN({ commit }, params) {
    return new Promise(function (resolve, reject) {
      axios.get(api_rkbmn + '/RKBMN/' + params.page + '/' + params.limit, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
        params: params,
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setListRKBMN', res.data)
          } else {
            commit('setListRKBMN', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getDetailRKBMN({ commit }, id) {
    return new Promise(function (resolve, reject) {
      axios.get(api_rkbmn + '/RKBMN/' + id, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        }
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setDetailRKBMN', res.data.data)
          } else {
            commit('setDetailRKBMN', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getLinkDocument({ commit }, filename) {
    return new Promise(function (resolve, reject) {
      axios.get(api_rkbmn + '/RKBMN/Lampiran/' + filename, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addRKBMN({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_rkbmn + '/RKBMN', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editRKBMN({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_rkbmn + '/RKBMN',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteRKBMN({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_rkbmn + '/RKBMN',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  addFileRKBMN({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(api_rkbmn + '/RKBMN/Lampiran', data, {
        headers: {
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then((res) => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  editFileRKBMN({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: api_rkbmn + '/RKBMN/Lampiran',
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  deleteFileRKBMN({ commit }, data) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'delete',
        url: api_rkbmn + '/RKBMN/Lampiran',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": atob(localStorage.getItem("token"))
        }
      })
        .then(res => {
          console.log(res.data)
          commit('setStatus')
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  getInfoListRKBMN({ commit }, params) {
    return new Promise(function (resolve, reject) {
      axios.get(api_rkbmn + '/Info/Daftar/' + params.page + '/' + params.limit, {
        headers: {
          "Authorization": atob(localStorage.getItem('token'))
        },
        params: params,
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.hasil == 'success') {
            commit('setInfoListRKBMN', res.data)
          } else {
            commit('setInfoListRKBMN', "")
          }
          resolve(res.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
}

const getters = {
  listRKBMN(state) {
    return state.listRKBMN;
  },
  detailRKBMN(state) {
    return state.detailRKBMN;
  },
  infoListRKBMN(state) {
    return state.infoListRKBMN;
  },
}

export default {
  state,
  mutations,
  actions,
  getters
}
